<template>
  <div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="series"
      :height="height"
      :width="width"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  name: "BarChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    height: {
      type: [String, Number],
      default() {
        return 400;
      },
    },
    width: {
      type: [String, Number],
      default() {
        return 500;
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          width: "100%",
          height: "100%",
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "80%",
          },
        },
        stroke: {
          curve: "smooth",
          width: 3,
          colors: ["#13DEB9"],
        },
        colors: ["#13DEB9"],
        tooltip: {
          fixed: {
            enabled: true,
          },
          x: {
            formatter: (val, opts) => {
              // Access start and end date from data
              const startDate =
                this.data[opts.dataPointIndex].period_start_date;
              const endDate = this.data[opts.dataPointIndex].period_end_date;

              // Format dates with time using moment.js (if imported)
              const startFormatted = moment(startDate).format("MM/DD");
              const endFormatted = moment(endDate).format("MM/DD");

              return `${startFormatted} - ${endFormatted}`;
            },
          },
          y: {
            formatter: (value) => {
              // Format value with commas for thousands
              return value.toLocaleString();
            },
          },
          marker: {
            show: false,
          },
        },
        yaxis: {
          min: 0,
          max:
            Math.max(
              ...this.data.map((item) =>
                parseFloat(item.total_saving.replace(/,/g, ""))
              )
            ) + 100, // Dynamically set max
          labels: {
            formatter: (value) => {
              return Math.abs(value) >= 1000
                ? `${Math.sign(value) * (Math.abs(value) / 1000).toFixed(0)}K`
                : value; // Format the y-axis labels
            },
          },
          title: {
            text: "Savings",
          },
        },
        xaxis: {
          categories: this.data.map((data) =>
            this.xAxisValues(data.period_start_date)
          ), // Use the formatted dates as categories
        },
        grid: {
          padding: {
            top: 10,
            bottom: 10,
            left: 5,
            right: 5,
          },
        },
      },
    };
  },

  watch: {
    // Watch for changes in the data prop
    data: {
      immediate: true,
      handler(newData) {
        this.updateSeries(newData);
      },
    },
  },

  methods: {
    // Format the date for x-axis labels
    xAxisValues(start) {
      const startDate = new Date(start);
      const endDate = new Date();
      let dateFormat = "MMM DD";

      if (startDate.getFullYear() !== endDate.getFullYear()) {
        dateFormat = "YYYY-MMM DD";
      }

      return moment(start).format(dateFormat);
    },

    // Update the series based on the chart data
    updateSeries(data) {
      this.series = [
        {
          name: "Total Savings",
          // data: data.map(item => item.total_saving),
          data: data.map((item) =>
            parseFloat(item.total_saving.replace(/,/g, ""))
          ),
        },
      ];
    },
  },
};
</script>
